/* eslint-disable react/no-unescaped-entities */
/***
*
*   FORGOT PASSWORD
*   Trigger a password reset process
*
**********/

import { Animate, Row, Form, Card } from 'components/lib';

export function ForgotPassword(){

  return(
    <Animate type='pop'>
      <Row title='Reset Your Password' color='black'>
        <Card restrictWidth center>

          <p className='mb-5'>Enter your email address and we'll send you
          instructions to reset your password.</p>

          <Form
            inputs={{
              email: {
                label: 'Email',
                type: 'email',
                required: true
              },
              source: {
                type: 'hidden',
                value: 'superAdministrator'
              }
            }}
            url='/api/auth/password/reset/request'
            method='POST'
            buttonColor='black'
            buttonText='Reset Password'
          />

        </Card>
      </Row>
    </Animate>
  );
}
