import { Fragment, useContext, useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import { ViewContext, useNavigate, Card, Table, TitleRow, Button } from 'components/lib';

export function Organizations(props) {
    const navigate = useNavigate();
    const context = useContext(ViewContext);
    const [organizationsList, setOrganizationsList] = useState([]);
    const [updateRef, setUpdateRef] = useState(0);
    const [isLoading, setLoading] = useState(false);

    const deleteOrganization = useCallback((data, cb) => {
        const id = data.id;
        context.modal.show({
            title: 'Disable Organization',
            text: 'Are you sure you want to disable this Organization?',
            buttonText: 'Disable',
            form: {
                id: {
                    type: 'hidden',
                    value: id,
                }
            },
            url: `/api/mc/organization/${id}`,
            destructive: true,
            method: 'DELETE',
        }, (form) => {
            setUpdateRef(Date.now());
        });
    }, [context.modal]);

    const reactivateOrganization = useCallback((data) => {
      const id = data.id;
      context.modal.show({
          title: 'Reactivate Organization',
          text: 'Are you sure you want to reactivate this Organization?',
          buttonText: 'Reactivate',
          form: {
              id: {
                  type: 'hidden',
                  value: id,
              }
          },
          url: `/api/mc/organization/undelete/${id}`,
          destructive: false,
          method: 'POST',
      }, () => {
          setUpdateRef(Date.now());
      });
  }, [context.modal]);

  useEffect(() => {
    setLoading(true);
    Axios.get('/api/mc/organizations')
        .then(result => {
            let list = [];
            if (result.data?.data?.length) {
                for (const o of (result?.data?.data || [])) {
                    let actions = {
                        edit: (data) => { navigate(`/organizations/${data.id}`) },
                    }
                    if (o.disabled) {
                        actions.markAsEnabled = reactivateOrganization;
                    } else {
                        actions.markAsDisabled = deleteOrganization;
                    }
                    list.push({
                        name: o.name,
                        created_at: o.created_at,
                        disabled: o.disabled,
                        id: o.id,
                        actions,
                    });
                }
                setOrganizationsList(list);
            }
            setLoading(false);
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
        });

    }, [updateRef, deleteOrganization, navigate, reactivateOrganization]);

    return (
        <Fragment>
            <TitleRow>
                <Button small text='Create New Organization' goto='/organizations/create' />
            </TitleRow>
            <Card>
                <Table
                    data={ organizationsList }
                    loading={ isLoading }
                    show={['name', 'created_at', 'disabled']}
                >
                </Table>
            </Card>
        </Fragment>
    );
    
}